<template>

     <template v-if="currentSchoolyear && adminSchoolyear">

        <el-card shadow="hover">
            <el-row align="middle">
                <el-col :span="12">
                    <div class="text-left">
                        <h3>{{ $t('establishmentsList') }}</h3>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="text-right">
                        <el-button size="default" @click="addItem()">
                            {{ $t('add') }}
                        </el-button>
                    </div>
                </el-col>
            </el-row>

            <el-divider></el-divider>

            <el-table :data="pagedTableData" :empty-text="$t('noData')" style="width: 100%" border stripe v-loading="isLoading">

                <el-table-column type="index" :index="indexMethod"> </el-table-column>
                <el-table-column sortable :label="$t('location')" prop="location"> </el-table-column>

                <el-table-column align="right">

                    <template #header>
                        <el-input v-model="search" size="small" :placeholder="$t('search')" style="width: 250px"/>
                    </template>

                    <template #default="scope">
                        <el-button size="small" @click="editItem(scope.$index, scope.row)">
                            {{ $t('edit') }}
                        </el-button>
                        <el-button size="small" type="danger" @click="deleteItem(scope.$index, scope.row)">
                            {{ $t('delete') }}
                        </el-button>
                    </template>

                </el-table-column>

            </el-table>

            <div style="text-align: center; margin-top: 20px">

                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="setPage"
                    :hide-on-single-page="true"
                    :page-size="pageSize"
                    :total="total">
                </el-pagination>

            </div>
        </el-card>

        <el-dialog :title="$t('addEstablishment')" v-model="addDialog" width="50%">

            <el-form
                label-width="200px"
                label-position="left"
                hide-required-asterisk
                :model="addEstablishment"
                :ref="addEstablishmentForm"
                :rules="rules">

                <el-form-item prop="location" :label="$t('location')">
                    <el-input
                        :placeholder="$t('location')"
                        v-model="addEstablishment.location"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(addEstablishmentForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(addEstablishmentForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

        <el-dialog :title="$t('editEstablishment')" v-model="editDialog" width="50%">

            <el-form
                label-width="200px"
                label-position="left"
                hide-required-asterisk
                :model="editEstablishment"
                :ref="editEstablishmentForm"
                :rules="rules">

                <el-form-item prop="location" :label="$t('location')">
                    <el-input
                        :placeholder="$t('location')"
                        v-model="editEstablishment.location"/>
                </el-form-item>

            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelForm(editEstablishmentForm)">{{ $t('cancel') }}</el-button>
                    <el-button type="primary" @click="submitForm(editEstablishmentForm)">{{ $t('confirm') }}</el-button>
                </span>
            </template>

        </el-dialog>

     </template>

     <el-alert :title="$t('noCurrentSchoolyearWarning')" type="warning" v-else/>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import fr from 'element-plus/lib/locale/lang/fr'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'Establishments',
    mixins: [accessManager],
    data() {
      return {
        primaryColor: process.env.VUE_APP_PRIMARY_COLOR,
        editEstablishment: {
            location: ''
        },
        addEstablishment: {
            location: ''
        },
        rules: {
            location: [
                {
                    required: true,
                    message: this.$t('fieldRequired'),
                    trigger: 'blur',
                }
            ]
        },
        editEstablishmentForm: 'editEstablishmentForm',
        addEstablishmentForm: 'addEstablishmentForm',
        currentEditItem: null,
        editDialog: false,
        isLoading: false,
        addDialog: false,
        tableData: [],
        pageSize: 6,
        locale: fr,
        search: '',
        total: 0,
        page: 1
      }
    },
    created() {

      this.fetchEstablishments()

    },
    computed:{
      ...mapState({
        userData: state => state.session.userData
      }),
      ...mapGetters({
        getSettingValue: 'settings/getSettingValue'
      }),
      pagedTableData() {

        this.total = this.searching.length

        return this.searching.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)

      },
      searching() {

        if (!this.search) {
            this.total = this.tableData.length
            return this.tableData
        }

        this.page = 1

        return this.tableData.filter(data => data.location.toLowerCase().includes(this.location.toLowerCase()))

      }
    },
    methods: {
      ...mapActions({
         fetchEstablishmentsDB: 'establishments/fetchEstablishmentsDB',
      }),
      setPage (val) {

         this.page = val

      },
      indexMethod(index) {

         return index + ((this.page - 1) * this.pageSize) + 1

      },
      async fetchEstablishments(){

        if (!this.adminSchoolyear) return

        this.isLoading = true

        let resp = await this.fetchEstablishmentsDB({
            action: 'getEstablishments',
            params: {
                schoolyear: this.adminSchoolyear
            }
        })

        if (resp.success){

            if (resp.data) this.tableData = resp.data

        }else{

            this.$message.error(this.$t('anErrorOccured'))

        }

        this.isLoading = false

      },
      addItem(){

           if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
           }

           this.addDialog = true

      },
      async editItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.currentEditItem = item

            this.editEstablishment.location = item.location

            this.editDialog = true

      },
      deleteItem(index, item){

            if (this.lockedData){
                this.$message.warning(this.$t('lockedData'))

                return
            }

            this.$confirm(this.$t('deleteEstablishmentQuestion'), this.$t('delete'), {
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
            })
            .then(async () => {

                let task = await this.fetchEstablishmentsDB({
                    action: 'deleteEstablishment',
                    params: {
                        id: item.id
                    }
                })

                if (task.success){

                    this.$message.success(this.$t('success'))
                    this.fetchEstablishments()

                }else{

                    this.$message.error(this.$t('anErrorOccured'))

                }

            })
            .catch(() => {

            })

      },
      submitForm(ref){

            this.$refs[ref].validate(async (valid) => {
                if (valid) {

                    if (ref === this.addEstablishmentForm){

                        let task = await this.fetchEstablishmentsDB({
                            action: 'addEstablishment',
                            params: {
                                location: this.addEstablishment.location,
                                schoolyear: this.adminSchoolyear
                            }
                        })

                        if (task.success) this.$message.success(this.$t('success'))
                        else this.$message.error(this.$t('anErrorOccured'))

                    }else{

                        let newData = {}

                        if (this.currentEditItem.location !== this.editEstablishment.location) {
                            newData['location'] = this.editEstablishment.location
                        }

                        if (!this.$_.isEmpty(newData)){
                            let task = await this.fetchEstablishmentsDB({
                                action: 'editEstablishment',
                                params: {
                                    id: this.currentEditItem.id,
                                    data: newData
                                }
                            })

                            if (task.success) this.$message.success(this.$t('success'))
                            else this.$message.error(this.$t('anErrorOccured'))
                        }

                    }

                    this.cancelForm(ref)
                    this.fetchEstablishments()

                } else {
                    return false
                }
            })

      },
      cancelForm(ref){

            this.$refs[ref].resetFields()

            if (ref === this.addEstablishmentForm) this.addDialog = false
            else this.editDialog = false

      }
    },
}
</script>